import React from "react";

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (
    <svg viewBox="0 0 106 28" height="30px">
      <path d="M18.78,23.08H6.33v-2.44h12.45V23.08z" fill="#666666" />
      <path
        fill="#666666"
        d="M18.6,14.03h3.31c0.02,0.78,0.27,1.42,0.77,1.9c0.5,0.48,1.14,0.73,1.93,0.73c0.91,0,1.61-0.27,2.09-0.81
            s0.72-1.33,0.72-2.35V0.2h3.39v13.32c0,1.93-0.54,3.44-1.63,4.51c-1.09,1.07-2.6,1.61-4.53,1.61c-1.82,0-3.29-0.51-4.39-1.54
            C19.15,17.08,18.6,15.72,18.6,14.03z"
      />
      <path
        d="M38.06,19.46c-1.36,0-2.48-0.39-3.36-1.17c-0.88-0.78-1.31-1.8-1.31-3.05c0-1.24,0.46-2.22,1.38-2.94
		c0.92-0.72,2.23-1.13,3.94-1.24l3.69-0.21v-1c0-0.73-0.23-1.3-0.69-1.7c-0.46-0.4-1.1-0.61-1.92-0.61c-0.77,0-1.39,0.17-1.87,0.51
		c-0.48,0.34-0.78,0.81-0.88,1.4h-3.05c0.07-1.33,0.64-2.41,1.71-3.24c1.07-0.83,2.48-1.25,4.23-1.25c1.73,0,3.12,0.42,4.16,1.27
		c1.04,0.84,1.56,1.97,1.56,3.38v9.63h-3.17V17h-0.07c-0.4,0.75-0.99,1.34-1.79,1.79C39.81,19.23,38.96,19.46,38.06,19.46z
		 M39.04,16.98c0.94,0,1.73-0.28,2.38-0.84c0.65-0.56,0.97-1.25,0.97-2.09v-1.07l-3.23,0.2c-0.79,0.05-1.4,0.25-1.84,0.59
		c-0.44,0.34-0.65,0.78-0.65,1.34c0,0.57,0.22,1.03,0.65,1.37S38.33,16.98,39.04,16.98z"
        fill="#666666"
      />
      <path
        d="M60.6,10.24h-3.1c-0.14-0.78-0.47-1.41-1-1.89s-1.21-0.72-2.06-0.72c-1.04,0-1.86,0.41-2.48,1.23
		c-0.62,0.82-0.92,1.95-0.92,3.38c0,1.45,0.31,2.58,0.92,3.4c0.62,0.81,1.45,1.22,2.51,1.22c0.83,0,1.5-0.22,2.03-0.67
		c0.53-0.44,0.87-1.07,1.02-1.87h3.1c-0.13,1.57-0.76,2.82-1.88,3.77c-1.12,0.95-2.56,1.42-4.31,1.42c-2.06,0-3.69-0.65-4.91-1.95
		c-1.21-1.3-1.82-3.07-1.82-5.32c0-2.22,0.61-3.99,1.82-5.31c1.21-1.31,2.84-1.97,4.88-1.97c1.79,0,3.25,0.5,4.36,1.5
		C59.87,7.45,60.49,8.71,60.6,10.24z"
        fill="#666666"
      />
      <path
        d="M73.69,17.56c-1.22,1.3-2.87,1.95-4.93,1.95s-3.71-0.65-4.93-1.95c-1.22-1.3-1.83-3.07-1.83-5.32
		c0-2.24,0.62-4.02,1.85-5.32c1.23-1.31,2.87-1.96,4.92-1.96c2.06,0,3.7,0.65,4.93,1.96c1.23,1.31,1.84,3.08,1.84,5.32
		C75.52,14.49,74.91,16.27,73.69,17.56z M66.25,15.68c0.62,0.82,1.45,1.23,2.51,1.23c1.06,0,1.89-0.41,2.51-1.23
		c0.62-0.82,0.92-1.97,0.92-3.44c0-1.47-0.31-2.61-0.92-3.44c-0.62-0.82-1.45-1.23-2.51-1.23c-1.05,0-1.88,0.41-2.5,1.23
		c-0.62,0.82-0.93,1.97-0.93,3.44C65.32,13.72,65.63,14.87,66.25,15.68z"
        fill="#666666"
      />
      <path
        d="M85.46,19.46c-1.01,0-1.9-0.23-2.67-0.69c-0.77-0.46-1.36-1.1-1.77-1.93h-0.07v2.39h-3.22V0.2h3.28v7.45h0.08
		c0.4-0.83,0.98-1.47,1.74-1.94c0.77-0.47,1.64-0.7,2.63-0.7c1.77,0,3.17,0.65,4.21,1.95s1.56,3.06,1.56,5.28
		c0,2.23-0.52,4-1.55,5.28C88.64,18.81,87.23,19.46,85.46,19.46z M84.44,7.72c-1.02,0-1.85,0.42-2.49,1.25
		C81.32,9.79,81,10.89,81,12.24c0,1.36,0.32,2.46,0.96,3.29c0.64,0.83,1.47,1.24,2.49,1.24c1.06,0,1.89-0.41,2.5-1.22
		s0.92-1.92,0.92-3.3c0-1.39-0.31-2.49-0.92-3.3C86.32,8.12,85.49,7.72,84.44,7.72z"
        fill="#666666"
      />
    </svg>
  );
}
